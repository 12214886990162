import { PROPERTY_LISTING_TYPE, SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM } from "./enum";
import { ScrollIntoViewOptionsInterface } from "./interfaces";

export const RIGHT_PANE_TAB_SEARCH_PARAM_TITLE = "right_pane_tab" as const;
export const AVAILABILITY_AND_PRICING = "Availability and pricing";
export const MARKETING_DETAILS = "Marketing details";

export const DETAILS_AND_RULES = "Details and rules";
export const DETAILS_AND_RULES_SUBTYPE_TITLES = {
  gas: "Does the property have a mains gas supply?*",
  epc: "Is there a valid EPC?",
  features: "Features",
  conditions: "Conditions",
  parking: "Parking"
} as const;

const EPC_RATING_ARRAY = ["A", "B", "C", "D", "E", "F", "G"];
export const EPC_RATING_MAP = EPC_RATING_ARRAY.map((grade) => ({
  name: grade,
  value: grade
}));

export const EPC_CONSTANTS = {
  RENT_HEADING:
    "A valid EPC (Energy Performance Certificate) is required to rent a property in the UK.",
  SALE_HEADING:
    "A valid EPC (Energy Performance Certificate) is required to sell a property in the UK.",
  FIND_EVERGY_CERTIFICATE_LINK:
    "https://find-energy-certificate.digital.communities.gov.uk/find-a-certificate/search-by-postcode",
  DROPDOWN_TITLE: "Enter the EPC Rating",
  DROPDOWN_PLACEHOLDER: "Select rating",
  DROPDOWN_ERROR: "EPC rating is required.",
  POPUP_TITLE: "Legal requirement",
  POPUP_CAPTION:
    "Energy Performance Certificates (EPCs) are needed whenever a property is: built, sold or rented. You must order an EPC for potential buyers & tenants before you market the property to sell or rent.",
  POPUP_LINK: "https://www.gov.uk/buy-sell-your-home/energy-performance-certificates",
  POPUP_BUTTON: "Confirm",
  BOOK_GSC_EPC_LINK:
    "https://5pw4my7mgfg.typeform.com/to/v0YDfa2h?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx#hubspot[…]l=xxxxx"
} as const;

export const REQUEST_IMPERSONATE_FOR_OFFER_TEXT = "Request access for creating offer";
export const REQUEST_IMPERSONATE_FOR_OFFER_LABEL = "Request access";
export const COPY_DRAFT_OFFER_LINK_LABEL = "Copy Link";

export const PROPERTY_TYPE = "Property Type";
export const ROOMS = "Rooms";
export const numberToString: Record<number, string> = {
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten",
  11: "Eleven",
  12: "Twelve",
  13: "Thirteen",
  14: "Fourteen",
  15: "Fifteen",
  16: "Sixteen",
  17: "Seventeen",
  18: "Eighteen",
  19: "Nineteen",
  20: "Twenty",
  21: "Twenty-One",
  22: "Twenty-Two",
  23: "Twenty-Three",
  24: "Twenty-Four",
  25: "Twenty-Five",
  26: "Twenty-Six",
  27: "Twenty-Seven",
  28: "Twenty-Eight",
  29: "Twenty-Nine",
  30: "Thirty"
};
export enum BEDROOM_TYPE {
  double = "double",
  single = "single"
}

export enum BATHROOM_FEATURES {
  Bath = "Bath",
  Sink = "Sink",
  Shower = "Shower",
  ShowerOverBath = "ShowerOverBath",
  Toilet = "Toilet"
}

export enum DURATION_TYPE {
  Month = "Month",
  Week = "Week"
}

export const NO_GAS_CONFIRM_POPUP_TITLE = "Legal requirement";
export const GAS_SUPPLY_ARTICLE_LINK =
  "https://propertyloophelp.zendesk.com/hc/en-gb/articles/4406546511633";

export const EDITABLE_ACCORDION_CANCEL_MODAL_TITLE = "Are you sure you want to cancel?";

export const EMPTY_CONDITION_PLACEHOLDER = "Any rules or conditions you have for the tenancy.";
export const CONDITIONS_VALIDATION_ERROR = "Please select at least one condition";

export const COUNCIL_TAX = "Council Tax";

export const EMPTY_COUNCIL_TAX_PLACEHOLDER =
  "Annual fee you pay to your local council for public services";

export enum COUNCIL_TAX_BAND {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H"
}

export const CONDITION_NONE_ID = 81;

export const PARKING_TYPE_VALIDATION_ERROR =
  "Atleast one of the available options must be selected";
export const EMPTY_PARKING_PLACEHOLDER = "Details on your property's parking situation.";

export const UPLOAD_MEDIA = "Upload media";

/* StreetView constants start */
export const STREET_VIEW = "Street view";
export const STREET_VIEW_TEXT =
  "You can edit the google street view of the property that will show on the listing.";
export const STREET_VIEW_EDIT_TEXT = "Edit street view for this property";
export const STREET_VIEW_ERROR_TEXT =
  "We couldn’t add the Google Street View for this property automatically.";
export const STREET_VIEW_UNAVAILABLE_TEXT =
  "Please find the view on Google, then copy and paste the link below.";
export const STREET_VIEW_ENABLE_TEXT = "Do you want to enable street view for this property?";
export const STREET_VIEW_DISABLED_TEXT = "Street view is disabled for this property";
export const STREET_VIEW_INVALID_URL_TEXT = "Please enter a valid Street View URL.";
export const STREET_VIEW_MANUAL_LINK_HELPER_TEXT =
  "For instructions on adding a manual link, visit our";
export const VALID_STREET_VIEW_URL_HOST_NAMES = ["google.com", "google.co.uk"];
export const STREET_VIEW_MODE_URL_IDENTIFIER = "3a";
// This are the values we need to subtract from the zoom and pitch in order to view the extact street view in our small container, this is for custom street view url
export const SUBTRACT_VALUE_FROM_ZOOM_IN_CUSTOM_URL = 80;
export const SUBTRACT_VALUE_FROM_PITCH_IN_CUSTOM_URL = 90;

export const STREET_VIEW_COMP_ARRAY_MIN_LENGTH = 5;
/* StreetView constants end */

export const LISTING_DESCRIPTION = "Listing description";
export const LISTING_DESCRIPTION_PLACEHOLDER = `E.g. The Superior two-bedroom apartments at Cheval Gloucester Park are perfect for families, couples, friends or colleagues sharing while staying in London for one night or more. These apartments are situated on floors one to three.\nEach apartment has a fully fitted kitchen with modern appliances as well as a Nespresso machine with capsules and takeaway cups. The Superior two-bedroom apartments at Cheval Gloucester Park are perfect for families, couples, friends or colleagues sharing while staying in London for one night or more. These apartments are situated on floors one to three.`;
export const LISTING_DESCRIPTION_SUB_TITLE =
  "What's special about your property? Why will renters love living here?";
export const AI_ASSIST_LINK_TITLE = "Click for AI assist";
export const AI_ASSIST_IN_PROGRESS_TEXT =
  "Ai Assist is generating your property description... Please wait";
export const AI_ASSIST_BULLET_POINTS_IN_PROGRESS_TEXT =
  "Ai Assist is generating your property bullet points... Please wait";

export const FAILED_TO_GENERATE_PROPERTY_DESC_MESSAGE =
  "Failed to generate property description. Please enter description manually.";

export const FAILED_TO_GENERATE_BULLET_POINTS_MESSAGE =
  "Failed to generate bullet points. Please enter bullet points manually.";

export enum OVERALL_PROPERTY_VALIDATION_POPUP_TRIGGERS {
  LIST = "List",
  AI_ASSIST = "AI Assist",
  AI_ASSIST_BULLET_POINTS = "AI Assist Bullet Points"
}
export const OVERALL_PROPERTY_VALIDATION_POPUP_TITLES = {
  [OVERALL_PROPERTY_VALIDATION_POPUP_TRIGGERS.LIST]:
    "Property cannot be listed because it's missing the below information.",
  [OVERALL_PROPERTY_VALIDATION_POPUP_TRIGGERS.AI_ASSIST]:
    "Please fill in the following information for AI assist to generate a property description",
  [OVERALL_PROPERTY_VALIDATION_POPUP_TRIGGERS.AI_ASSIST_BULLET_POINTS]:
    "Please fill in the following information for AI assist to generate a property main bullet points"
};

/* Marketing constants start */
export const MARKETING_FEEDS = "Marketing";
export const RIGHTMOVE = "Rightmove";
export const ZOOPLA = "Zoopla";
export const ON_THE_MARKET = "OnTheMarket";
export const ALL_OTHER_PORTALS = "All Other Portals";
export const MARKETING_FEEDS_CAPTION =
  "Please wait 15mins for the property to update on the portals";
export const MARKETING_FEEDS_KEY = "marketingFeedsAction";
export const PROPERTY_LISTED_STATUS_TEXT = "Active";
export const PROPERTY_UNLISTED_STATUS_TEXT = "Not Active";
/* Marketing constants end */

export const GETTING_AROUND = "Getting around";
export const GETTING_AROUND_PLACEHOLDER = `E.g. The area is great for professionals looking to commute to the City. You’ll enjoy nearby access to London Underground’s Jubilee line station and the DLR. The streets are also very car-friendly and there are many Santander Cycles within walking distance...`;
export const GETTING_AROUND_SUB_TITLE =
  "How is transportation here? What transport links are nearby?";

export const LOCAL_LIFE = "Local life";
export const LOCAL_LIFE_PLACEHOLDER = `E.g. This area is known for its bustling nightlife and many restaurants. This neighbourhood is synonymous with families and professionals, so you’ll enjoy lots of events for kids as well as a close-knit community feel. Nearby you can find farmer’s markets and a good number of independent retailers. Renters can enjoy the outdoors at Hyde Park, which is 10 minutes away...`;
export const LOCAL_LIFE_SUB_TITLE = `What's the neighbourhood and community like? What is there to do around here?`;

export const STATE_KEY_FIELD_NAME = {
  uploadMedia: {
    errorField: UPLOAD_MEDIA.toLowerCase(),
    dbKey: "upload_media"
  },
  description: {
    errorField: LISTING_DESCRIPTION.toLowerCase(),
    dbKey: "desc_overview"
  },
  gettingAround: {
    errorField: GETTING_AROUND.toLowerCase(),
    dbKey: "desc_getting_around"
  },
  localLife: { errorField: LOCAL_LIFE.toLowerCase(), dbKey: "desc_local_life" }
};

export const SALE_STATE_KEY_FIELD_NAME = {
  description: {
    errorField: LISTING_DESCRIPTION.toLowerCase(),
    dbKey: "sale_desc_overview"
  },
  gettingAround: {
    errorField: GETTING_AROUND.toLowerCase(),
    dbKey: "sale_desc_getting_around"
  },
  localLife: { errorField: LOCAL_LIFE.toLowerCase(), dbKey: "sale_desc_local_life" }
} as const;

export const LISTING_DESCRIPTION_DECLARATION = {
  [PROPERTY_LISTING_TYPE.RENT]:
    "I hereby certify that my property listing and description are true and correct to the best of my knowledge. I understand that I am prohibited by law to make any false or misleading statements when advertising my property for let. I understand a false statement may disqualify me from any future tenancy and may cause recourse by future tenant(s) by means of compensation.",
  [PROPERTY_LISTING_TYPE.SALE]:
    "I hereby certify that my property listing and description are true and correct to the best of my knowledge. I understand that I am prohibited by law to make any false or misleading statements when advertising my property for sale. I understand a false statement may disqualify me from any future property transaction and may cause recourse by future buyer(s) by means of compensation."
};

export const FEATURES_EMPTY_PLACEHOLDER = "Basic information about your tenancy and the property.";

export const FEATURE_TYPES = {
  FURNITURE: "Furniture",
  KITCHEN: "Kitchen",
  HEATING_AND_COOLING: "Heating and Cooling",
  SAFETY: "Safety",
  OTHER: "Other"
};

export const FURNITURE_IDS = [72, 73, 75];

export const NO_PETS_ID = 76;
export const PETS_RELATED_IDS = [39, 69];

export const OTHERS_IDS = [16, 34, 35, 39, 69, 96, 97, 98, 99, 100, 9];
export const OUTSIDE_SPACE_IDS = [13, 114, 115, 116];
export const FEATURES_NONE_ID = 101;
export const FEATURES_OUTSIDE_SPACE_NONE_ID = 142;
export const SMOKE_ALARM_ID = 92;
export const FEATURES_VALIDATION_ERROR = "Please select at least one from each category.";

export enum LISTING_DETAILS_ENUM {
  basicDetails = "basic_details",
  amenities = "amenities",
  listingDescription = "listing_description",
  epcAndCouncilTax = "epc_and_council_tax",
  location = "location",
  marketingDetails = "marketing_details",
  propertyType = "property_type",
  roomDetails = "room_details",
  featuresAndRules = "features_and_rules",
  mainBulletPoints = "main_bullet_points"
}

export const AVAILABLE_FROM_VALIDATION_ERROR = "Move in date is required";
export const AVAILABLE_FROM_CALENDAR_BREAKPOINT = 1800;

export const UPLOAD_MEDIA_RENT_FOOTER_TEXT = `I confirm that the photos I am uploading are of the Property I am advertising for let. I
understand that I am prohibited by law to advertise my Property with any false or misleading
photos when advertising my property for let and that any inaccurate images provided in this
advertisement could invalidate any future tenancy and or cause recourse by my future tenants
for compensation if something is falsely advertised.`;

export const UPLOAD_MEDIA_SALE_FOOTER_TEXT = `I confirm that the photos I am uploading are of the Property I am advertising for sale. I 
understand that I am prohibited by law to advertise my Property with any false or misleading
photos when advertising my property for sale and that any inaccurate images provided in this
advertisement could invalidate any future sale and or cause recourse by my future buyers for
compensation if something is falsely advertised.`;

export const UPLOAD_MEDIA_HEADER_TEXT = " Upload your own media and videos of the property.";

export const MAIN_BULLET_POINTS = "Main bullet points";

/*
----------------- Constants for Scroll element start --------------------------
*/

export enum LISTING_DETAILS_ACCORDION_ENUM {
  NO_SECTION = "",
  VIEWING_URL = "viewingUrl",
  ASSIGNED_AGENT = "assignedAgent",
  ASSIGNED_VIEWING_AGENT = "assignedViewingAgent",
  ASSIGNED_PROPERTY_MANAGER = "assignedPropertyManager",
  MARKETING_DETAILS = "marketingDetails",
  COUNCIL_TAX = "councilTax",
  PROPERTY_TYPE = "propertyType",
  ROOMS = "rooms",
  FEATURES = "features",
  CONDITIONS = "conditions",
  PARKING = "parking",
  LISTING_DESCRIPTION = "listingDescription",
  GETTING_AROUND = "gettingAround",
  LOCAL_LIFE = "localLife",
  GAS_SUPPLY = "gasSupply",
  MEDIA = "media",
  STREET_VIEW = "streetView",
  MARKETING_FEEDS = "marketingFeeds",
  MAIN_BULLET_POINTS = "mainBulletPoints"
}

export const PROPERTY_RIGHT_PANE_SCROLL_OPTIONS: ScrollIntoViewOptionsInterface = {
  block: "start",
  behavior: "smooth"
};

/*
----------------- Constants for Scroll element ends --------------------------
*/

/*
----------------- Constants for right pane events start --------------------------
*/
export const REQUEST_SWITCH_TAB = "REQUEST_SWITCH_TAB";
export const SWITCH_TAB_OK = "SWITCH_TAB_OK";
export const PROPERTY_CLICK = "PROPERTY_CLICK";
export const NAVIGATE_PROPERTY = "NAVIGATE_PROPERTY";
export const NAVIGATE_BACK = "NAVIGATE_BACK";
export const BACK_CLICK = "BACK_CLICK";
export const STATUS_CLICK = "STATUS_CLICK";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const TRANSFER_OWNERSHIP_CLICK = "TRANSFER_OWNERSHIP_CLICK";
export const TRANSFER_OWNERSHIP_ACTION = "TRANSFER_OWNERSHIP_ACTION";
export const RIGHT_PANE_TAB_CLICK = "RIGHT_PANE_TAB_CLICK";
export const RIGHT_PANE_TAB_CHANGE = "RIGHT_PANE_TAB_CHANGE";

/*
----------------- Constants for right pane events ends --------------------------
*/

export const PROPERTY_STATUS = {
  DRAFT: {
    name: "Draft",
    value: "draft"
  },
  PENDING: {
    name: "Pending",
    value: "pending"
  },
  LISTED: {
    name: "Listed",
    value: "active"
  },
  UNLISTED: {
    name: "Unlisted",
    value: "inactive"
  },
  ALL: {
    name: "All",
    value: "all"
  }
};

export const SALE_PROPERTY_OFFER_STATUS = {
  AVAILABLE: {
    name: "Available",
    value: null
  },
  UNDER_OFFER: {
    name: "Under Offer",
    value: "under_offer"
  },
  SOLD: {
    name: "Sold STC",
    value: "sold"
  }
};

export const TOAST_LISTED_SUCCESS_MSG = "Successfully listed the property";

export const TOAST_DRAFT_OFFER_LINK_COPIED_SUCCESS = "Offer link copied";

export const LISTING_DETAILS_ACCORDION_ORDER = [
  LISTING_DETAILS_ACCORDION_ENUM.VIEWING_URL,
  LISTING_DETAILS_ACCORDION_ENUM.ASSIGNED_AGENT,
  LISTING_DETAILS_ACCORDION_ENUM.MARKETING_DETAILS,
  LISTING_DETAILS_ACCORDION_ENUM.COUNCIL_TAX,
  LISTING_DETAILS_ACCORDION_ENUM.PROPERTY_TYPE,
  LISTING_DETAILS_ACCORDION_ENUM.ROOMS,
  LISTING_DETAILS_ACCORDION_ENUM.GAS_SUPPLY,
  LISTING_DETAILS_ACCORDION_ENUM.FEATURES,
  LISTING_DETAILS_ACCORDION_ENUM.CONDITIONS,
  LISTING_DETAILS_ACCORDION_ENUM.PARKING,
  LISTING_DETAILS_ACCORDION_ENUM.MEDIA,
  LISTING_DETAILS_ACCORDION_ENUM.LISTING_DESCRIPTION,
  LISTING_DETAILS_ACCORDION_ENUM.GETTING_AROUND,
  LISTING_DETAILS_ACCORDION_ENUM.LOCAL_LIFE,
  LISTING_DETAILS_ACCORDION_ENUM.MAIN_BULLET_POINTS
];

export const SALE_LISTING_DETAILS_ACCORDION_ORDER = [
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.VIEWING_URL,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.ASSIGNED_AGENT,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.MARKETING_DETAILS,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.PROPERTY_TYPE,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.ROOMS,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.EPC_GSC,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.FEATURES,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.LISTING_DESCRIPTION,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.UPLOAD_MEDIA,
  SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM.MAIN_BULLET_POINTS
];

/*
----------------- Constants for property media starts --------------------------
*/
export enum MEDIA_TYPES {
  PHOTOS = "photos",
  VIDEOS = "videos",
  FLOOR_PLANS = "floorplan",
  THREE_D_TOUR = "3D tour"
}

export enum MEDIA_SUB_SECTION_TYPE {
  COMPONENT = "component",
  TEXT = "text"
}

export const MAX_PHOTOS_UPLOAD = 30;
export const MIN_PHOTOS_UPLOAD = 5;
export const MIN_PHOTO_SIZE_IN_KB = 30;
export const MAX_PHOTO_SIZE_IN_KB = 80000;
export const MAX_IMAGE_SIZE_IN_KB = 80000;
export const PHOTO_UPLOAD_ERRORS = {
  SMALL_IMAGE: `The file is too small. Min ${MIN_PHOTO_SIZE_IN_KB}KB.`,
  LARGE_IMAGE: `The file is too large. Max ${MAX_PHOTO_SIZE_IN_KB / 1000}MB is allowed.`,
  UPLOAD_ERROR: "There was an error uploading the file.",
  MIN_IMAGES:
    "Cannot hide or delete your 5th photo as your property is currently listed. A minimum of 5 active photos is required."
};

export const MEDIA_ERROR_REF = "media_error";
export const FEATURES_ERROR_REF = "features_error_ref";

export const MAX_VIDEOS_UPLOAD = 1;
export const MAX_VIDEO_DURATION_IN_SECONDS = 10 * 60;
export const MIN_VIDEO_SIZE_IN_KB = 30;
export const MAX_VIDEO_SIZE_IN_KB = 1500000;
export const VIDEO_UPLOAD_ERRORS = {
  SMALL_FILE: `The file is too small. Min ${MIN_VIDEO_SIZE_IN_KB}KB`,
  LARGE_FILE: `The file is too large. Max ${MAX_VIDEO_SIZE_IN_KB / 1000}MB`,
  UPLOAD_ERROR: "There was an error uploading the file",
  VIDEO_DURATION: `The video is too long. Max ${MAX_VIDEO_DURATION_IN_SECONDS / 60} minutes`,
  UNSUPPORTED_FORMAT: "Unsupported file, only mp4 and mov formats are allowed"
};

export const SUPPORTED_VIDEO_FORMATS = {
  MP4: "mp4",
  MOV: "mov"
};

export const MAX_FLOOR_PLAN_UPLOAD = 3;
export const MIN_FLOOR_PLAN_IMAGE_SIZE_IN_KB = 30;
export const MAX_FLOOR_PLAN_IMAGE_SIZE_IN_KB = 80000;
export const FLOOR_PLAN_UPLOAD_ERRORS = {
  SMALL_IMAGE: `The file is too small. Min ${MIN_FLOOR_PLAN_IMAGE_SIZE_IN_KB}KB`,
  LARGE_IMAGE: `The file is too large. Max ${MAX_FLOOR_PLAN_IMAGE_SIZE_IN_KB / 1000}MB`,
  UPLOAD_ERROR: "There was an error uploading the file"
};

export const MAX_3D_TOUR_UPLOAD = 1;

export const MIN_BULLET_POINTS_REQUIRED = 5;

export const UPLOAD_MEDIA_POPUP_ACTIONS = {
  Upload: "Upload",
  Close: "Close"
};

export const MEDIA_IMAGE_CARD_INFO = {
  IS_DELETED: "is_deleted",
  IS_HIDDEN: "is_hidden",
  IS_LEAD_PHOTO: "lead_photo"
};

export enum MEDIA_VIDEO_CARD_INFO {
  IS_DELETED = "is_deleted",
  IS_HIDDEN = "is_hidden"
}
export const MEDIA_DROPDOWN_PLACEHOLDER = "Select room type";

export const UPLOAD_ACTION = {
  NEW: "new",
  EDIT: "edit"
};

export const VIDEO_PROCESSING_MESSAGES = {
  URI_PRESENT: "Please click apply and check after few minutes",
  URI_ABSENT: "Please check after few minutes"
};

export const THREE_D_TOUR_PLACEHOLDER = "Eg: https://my.matterport.com/show/?m=uRGXgoiYk9f&help=1";

export enum MEDIA_CARD_ICONS {
  COVER = "coverIcon",
  HIDE = "hideIcon",
  DELETE = "deleteIcon"
}

/*
----------------- Constants for property media ends --------------------------
*/

export const RIGHT_PANE_TABS = {
  LISTING_DETAILS: "Listing details",
  TEAMS: "Teams",
  OFFERS: "Offers",
  APPLICANTS: "Applicants"
};

export const RIGHT_PANE_TABS_FOR_COMPANY_MEMBER = {
  LISTING_DETAILS: "Listing details",
  APPLICANTS: "Applicants"
};

export const SALE_RIGHT_PANE_TABS = {
  LISTING_DETAILS: "Listing details",
  TEAMS: "Teams",
  APPLICANTS: "Applicants"
};

export const SALE_RIGHT_PANE_TABS_FOR_COMPANY_MEMBER = {
  LISTING_DETAILS: "Listing details",
  APPLICANTS: "Applicants"
};

export enum OWNERSHIP_TRANSFER_ACTIONS {
  ASSIGN = "Assign",
  INVITE = "Invite",
  ASSIGN_LANDLORD = "Assign landlord",
  INVITE_LANDLORD = "Invite landlord",
  TRANSFER_OWNERSHIP = "Transfer ownership",
  OWNERSHIP = "Ownership"
}

export enum CONFIRMATION_POPUP_CONTENT {
  ENCOUNTERED_PROBLEM = `Oh no…we've encountered a problem. Please try again but if the problem persists,
  please raise a support request`,
  RENTER_ERROR = "Error Code: User is a 'Renter' with an offer record."
}

export enum CONFIRMATION_MESSAGE_TYPES {
  NEW_LANDLORD = "newLandlord",
  EXISTING_LANDLORD = "existingLandlord",
  RENT_ERROR = "rentError",
  SUCCESS_FALSE = "successFalse"
}

export const SEARCH_OWNER_DROPDOWN_PLACEHOLDER = "Select an owner";

export enum INVITE_LANDLORD_FORM_LABELS {
  FIRST_NAME = "First name ",
  SURNAME = "Surname ",
  EMAIL = "Email ",
  PHONE_NUMBER = "Phone number"
}

export const NAMES_MINIMUM_LENGTH = 3;

export enum EPC_RATING_ENUM {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G"
}

export const MAIN_BULLET_POINTS_SECTION_SUBHEADER =
  "This section populates the key features on third party websites.";

/* AssignAgent constants start */
export const LISTING_AGENT = "Listing agent";
/* AssignAgent constants end */

/* Assign Viewing Agent constants start */
export const VIEWING_AGENT = "Viewing agent";
/* Assign Viewing Agent constants end */

export const PROPERTY_MANAGER = "Property manager";

export enum PropertyListType {
  FOR_SALE = "FOR_SALE",
  FOR_RENT = "FOR_RENT",
  FOR_RENT_FOR_SALE = "FOR_RENT_FOR_SALE"
}

export enum GAS_EPC_ENUM {
  gas = "gas",
  epc = "epc"
}
export const SALE_PROPERTY_DESC = {
  LISTING_DESCRIPTION: {
    PLACEHOLDER: `E.g. The Superior two-bedroom apartments at Cheval Gloucester Park are perfect for families, couples, friends or colleagues sharing while staying in London for one night or more. These apartments are situated on floors one to three.\nEach apartment has a fully fitted kitchen with modern appliances as well as a Nespresso machine with capsules and takeaway cups. The Superior two-bedroom apartments at Cheval Gloucester Park are perfect for families, couples, friends or colleagues sharing while staying in London for one night or more. These apartments are situated on floors one to three.`,
    SUB_TITLE: "What's special about your property? Why will buyers love living here?"
  },
  GETTING_AROUND: {
    PLACEHOLDER: `E.g. The area is great for professionals looking to commute to the City. You’ll enjoy nearby access to London Underground’s Jubilee line station and the DLR. The streets are also very car-friendly and there are many Santander Cycles within walking distance...`,
    SUB_TITLE: "How is transportation here? What transport links are nearby?"
  },
  LOCAL_LIFE: {
    PLACEHOLDER: `E.g. This area is known for its bustling nightlife and many restaurants. This neighbourhood is synonymous with families and professionals, so you’ll enjoy lots of events for kids as well as a close-knit community feel. Nearby you can find farmer’s markets and a good number of independent retailers. Buyers can enjoy the outdoors at Hyde Park, which is 10 minutes away...`,
    SUB_TITLE: `What's the neighbourhood and community like? What is there to do around here?`
  }
} as const;

// Property search API response (PL)
export interface SearchPropertyData {
  asking_price: string;
  available_from: Date;
  bathrooms: string;
  bedrooms: string;
  created_at: Date;
  full_count: string;
  id: string;
  images: string;
  marker_latitude: string;
  marker_longitude: string;
  originalimg: string;
  price: string;
  property_status: string;
  short_address: string;
  formatted_address: string;
  property_ref: number;
  type: number;
  url: string;
  listed_by: string;
  position?: string;
  overall_count?: string;
  rn?: string;
  updated_at?: Date;
  source_id?: string;
  cluster_count?: number;
}
export interface MapBoundsInterface {
  min_latitude: string;
  max_latitude: string;
  min_longitude: string;
  max_longitude: string;
}

export interface PlPropertySearchAPIResponse {
  rows: Array<SearchPropertyData>;
  rowCount: number;
  totalCount: number;
  bounds: MapBoundsInterface;
  found: boolean;
}

export interface PlServerPropertyData {
  in_house: boolean;
  property_name: string | null;
  pr_type: string | null;
  pr_type_id: number | null;
  floor: string | null;
  bedrooms: number;
  bathrooms: number;
  other_rooms: number;
  price: number | null;
  updated_at: string;
  available_from: string | null;
  conditions: string | null;
  utilsChecker: boolean;
  utils: {
    gas: 0 | 1;
    water: 0 | 1;
    electric: 0 | 1;
    internet: 0 | 1;
    council_tax: 0 | 1;
  };
  energy_efficiency_current: number | null;
  energy_efficiency_potential: number | null;
  epc_rating: string | null;
  desc_access_amenities: string | null;
  desc_additional_areas: string | null;
  desc_getting_around: string | null;
  desc_internal_lname: string | null;
  desc_listing: string | null;
  desc_other_things: string | null;
  desc_overview: string | null;
  desc_space: string | null;
  desc_whos_manag_prop: string | null;
  desc_local_life: string | null;
  latitude: string;
  longitude: string;
  street_view: {
    pov_heading: string | null;
    pov_pitch: string | null;
    zoom: string | null;
  };
  short_add: string;
  matterport: string;
  owner: {
    id?: string;
  };
  liked_users: string[];
  furnished_type: string | null;
  images: {
    orignalimg: string | null;
    is_360: number;
    is_360_video: number;
    is_tour: number;
    name: string;
    type: string;
    url: string;
    position: number;
    updated_date: string;
    listing_type: string;
  }[];
  vimeo: string;
  floorplan: {
    name: string;
    url: string;
    caption: string | null;
  }[];
  pr_sub_types: string | null;
  property_ref: number;
  local_authority: string;
  isPreview: boolean;
  council_tax_band: string | null;
  council_tax_amount: number | null;
  tagline: string | null;
  viewing_url: string | null;
  is_viewing_url_valid: boolean;
  room_tags: {
    name: string;
    room_type_id: number;
    room_name: string;
    tags: string;
  }[];
  agent: AgentInfo;
  main_bullet_points: {
    id: number;
    label: string;
  }[];
  property_state: string | null;
  sale_property_status: string;
  sale_offer_status: string | null;
  sale_agent_id: string | null;
  sale_area_sqm: number | null;
  sale_desc_getting_around: string | null;
  sale_desc_overview: string | null;
  sale_desc_local_life: string | null;
  sale_viewing_url: string | null;
  is_sale_viewing_url_valid: boolean;
  sale_price: number | null;
  sale_asking_price_prefix: string | null;
  sale_ground_rent: number | null;
  sale_service_charge: number | null;
  sale_tenure: number | null;
  sale_tenure_type: keyof typeof SALE_TENURE_TYPE_MAP | null;
  viewingAgent: AgentInfo;
  listed_by: AGENCY_NAMES;
  area_sq_ft: number | null;
  area_sq_m: number | null;
  source_id: string | null;
  street_view_enabled: number | null;
}

export interface PLServerApiResponse<T> {
  data: T;
  error: boolean;
  message: string;
  status: number;
  token: string;
}

interface AgentInfo {
  name: string;
  avatar: string | null;
  type: string | null;
  company: string | null;
  agent_id: string | null;
  email: string | null;
  mobile: number | null;
}

export const SALE_TENURE_TYPE_MAP = {
  freehold: "Freehold",
  leasehold: "Leasehold",
  share_of_freehold: "Share of freehold",
  commonhold: "Commonhold",
  feudal: "Feudal"
};

export const enum AGENCY_NAMES {
  PROPERTYLOOP = "PropertyLoop",
  FOXTONS = "Foxtons",
  DEXTERS = "Dexters",
  SAVILLS = "Savills",
  CHESTERTONS = "Chestertons",
  HAMPTONS = "Hamptons",
  STRUTANDPARKER = "Strut & Parker",
  MARSHANDPARSON = "Marsh & Parsons",
  WINKWORTH = "Winkworth",
  ACORNGROUP = "Acorn Group",
  JLL = "JLL"
}
